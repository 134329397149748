import { setDefaultOptions } from 'date-fns';
import enGB from 'date-fns/locale/en-GB/index';
import nlNL from 'date-fns/locale/nl/index';
import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import type { ResourceKey } from 'i18next';

export type Locale = (typeof Locale)[keyof typeof Locale];
export const Locale = {
  Dutch: 'nl-NL',
  English: 'en-GB',
} as const;

export type ImportFn = (language: string, namespace: string) => Promise<ResourceKey | boolean | null | undefined>;

export const DEFAULT_LOCALE = Locale.Dutch;

export function setDateFnsLocale(lang: Locale | (Record<never, never> & string)) {
  setDefaultOptions({ locale: lang === Locale.English ? enGB : nlNL });
}

i18next.use(initReactI18next).on('languageChanged', setDateFnsLocale);

export async function initI18n(
  importFn: ImportFn,
  locale?: Locale | (Record<never, never> & string),
  defaultNS?: string | false | readonly string[]
) {
  return i18next.use(resourcesToBackend(importFn)).init({
    // debug: import.meta.env.MODE === 'development',
    defaultNS,
    fallbackLng: DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: locale,
  });
}
